import React from 'react'
import _ from 'lodash'
import { VictoryChart, VictoryStack, VictoryArea } from 'victory'

class Benchmarks extends React.Component {
  constructor(props) {
    super(props)
    this.state = { data: this.getData() }
  }

  componentDidMount() {
    this.setStateInterval = window.setInterval(() => {
      this.setState({ data: this.getData() })
    }, 4000)
  }

  getData() {
    return _.range(7).map(() => {
      return [
        { x: 1, y: _.random(1, 5) },
        { x: 2, y: _.random(1, 10) },
        { x: 3, y: _.random(2, 10) },
        { x: 4, y: _.random(2, 10) },
        { x: 5, y: _.random(2, 15) }
      ]
    })
  }

  render() {
    return (
      <div className={this.props.className}>
        <VictoryChart animate={{ duration: 1000 }}>
          <VictoryStack colorScale={'red'}>
            {this.state.data.map((data, i) => {
              return <VictoryArea key={i} data={data} interpolation={'basis'} />
            })}
          </VictoryStack>
        </VictoryChart>
      </div>
    )
  }
}

export default Benchmarks
