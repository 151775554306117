import React from 'react'
import { graphql } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

// Components
import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import Benchmarks from '../components/benchmarks'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query PerformancePageQuery {
    performance: sanityPerformance(_id: { regex: "/(drafts.|)performancePage/" }) {
      title
      title1
      body
      buttonCta
      buttonLink
      titleHow
      howDescription
      autopilot
      autopilotImage {
        asset {
          _id
        }
      }
      autopilotSteps {
        description
        title
        icon {
          asset {
            _id
          }
        }
      }
      benefit
    }
  }
`

// Classes
const HeadingClass = 'dib relative w-100 w-60-ns f2 fw5 mt0 mb2'
const ParagraphClass = 'dib relative w-100 measure-wide gray fw4 mb4'

const PerformancePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const performance = (data || {}).performance

  if (!performance) {
    throw new Error(
      'Missing "Performance" page data. Open the studio at http://localhost:3333 and add "Performance" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={performance.title} />
      <article className="performance lh-copy" id="performance">
        {/* Performance */}
        <section className="dib relative w-100 black lh-copy overflow-hidden f5 bg-gradient">
          <div className="dib w-100 relative pt6 pt7-l">
            <PageHeader
              description={performance.body}
              heading={performance.title1}
              buttonBG="bg-white"
              button
              blank
              href="#benchmark"
              link={performance.buttonLink}
              cta={performance.buttonCta}
            />
          </div>
          <div className="db center mw8 ph4 pv6" id="benchmark">
            <Benchmarks className="dib relative w-100" />
          </div>
        </section>

        {/* How */}
        <section className="dib relative w-100 bg-white black lh-copy f5 overflow-hidden" id="how">
          <div className="db center mw8 ph4 pv6">
            <h2 className={HeadingClass}>{performance.titleHow}</h2>
            <p className={`${ParagraphClass} preline`}>{performance.howDescription}</p>
            <div className="row top-xs">
              <div className="col-xs-12 col-md-6">
                <img
                  className="dib relative w-100 mb4 mb0-l"
                  src={imageUrlFor(buildImageObj(performance.autopilotImage))}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="dib relative w-100 ph0 ph4-l">
                  <strong className="dib relative w-100 f3 fw5">{performance.autopilot}</strong>
                  {performance.autopilotSteps.map((object, index) => (
                    <div className="dib relative w-100 pv4" key={`autopilot-${index}`}>
                      <div className="dib flex-l top-xs">
                        <span className="dib relative w3 h3 tc mr4 overflow-hidden mb3 mb0-l">
                          {object.icon ? (
                            <img
                              className="dib relative w-100 fit"
                              src={imageUrlFor(buildImageObj(object.icon))}
                            />
                          ) : null}
                        </span>
                        <span className="dib relative w-100 measure tl">
                          <strong className="dib relative w-100 f4 fw4">{object.title}</strong>
                          <p className="dib relative w-100 dark-gray fw4">{object.description}</p>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Benefit */}
        <section
          className="dt relative vh-100 w-100 bg-brand-pink green lh-copy overflow-hidden f5 b tc"
          id="benefit"
        >
          <div className="dtc v-mid w-100 relative">
            <div className="db center mw8 ph4 pv0">
              <h3 className={HeadingClass}>{performance.benefit}</h3>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default PerformancePage
